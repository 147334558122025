#taskbar {
  overflow-x: clip;
  display: flex;
  position: fixed;
  height: 43px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(18, 67, 172, 1) 30%,
    rgba(38, 93, 222, 1) 80%,
    rgba(54, 134, 230, 1) 97%
  );
}

#taskbar div {
  height: inherit;
}

#startButton {
  height: inherit;
  bottom: 0px;
}

#rightMenu {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 98, 195, 1) 3%,
    rgba(9, 142, 237, 1) 80%,
    rgba(40, 178, 248, 1) 97%
  );
}

#rightMenu div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.active-apps {
  margin-left: 10px;
  display: flex;
  color: white;
}

.active-apps span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100px;
}
