#expandStartMenu {
  left: 0;
  position: absolute;
  z-index: -100;
  float: left;
  display: block;
  flex-direction: column;
  display: hidden;
  width: 450px;
}

#expandStartMenu div {
  font-size: xx-large;
}

.column {
  float: left;
  width: calc(50% - 1px);
}

#taskbar .column {
  height: auto;
}

.right {
  background-color: #d7e7ff;
  border-left: 2px solid #a9bfdc;
}

.left {
  background-color: white;
}

.topsection {
  background: linear-gradient(
    0deg,
    rgba(66, 140, 239, 1) 0%,
    rgba(12, 98, 207, 1) 68%,
    rgba(70, 137, 229, 1) 100%
  );
  padding-bottom: 20px;
  padding-top: 10px;
  border-top-right-radius: 10px;
}

.topsection span {
  font-size: x-large;
  margin-left: 20px;
}

.topsection img {
  margin-left: 10px;
  vertical-align: middle;
  border: 2px solid white;
  border-radius: 5px;
}

.topsection img:hover {
  cursor: pointer;
}

.bottomsection {
  background: linear-gradient(
    0deg,
    rgba(13, 99, 215, 1) 0%,
    rgba(42, 124, 227, 1) 68%,
    rgba(64, 135, 238, 1) 100%
  );
}

.bottomButtons {
  margin: 0px 10px;
  display: flex;
  float: right;
}

.bottomButtons div {
  margin-left: 10px;
}

.bottomButtons img {
  margin-right: 5px;
  vertical-align: middle;
}

#taskbar div.programItems {
  display: flex;
  height: fit-content;
}

.programItem img {
  margin-right: 5px;
}

.right .programItem div {
  border: 1px solid #deeafc;
  border-radius: 5px;
}

.right .programItem div:hover {
  background-color: white;
}

img {
  user-select: none;
}

hr {
  width: 75%;
  margin: 0 auto;
  border: 1px solid #b9d4fc;
}

.programItem span {
  display: inline-block;
  vertical-align: center;
  color: black;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: large;
  overflow: hidden;
  max-width: 150px;
}

a {
  text-decoration-line: none;
}

.programItem div {
  border: 1px solid white;
}

.programItem div:hover {
  border: 1px solid #deeafc;
}

.programItem + .programItem {
  margin-top: 5px;
}

#taskbar .programItem div {
  display: flex;
  margin: 5px;
  padding: 5px;
}

#taskbar .programItem {
  height: unset;
}

.programItem:hover {
  background-color: #deeafc;
}

.programItem:hover div {
  border: 1px solid #deeafc;
}

.programItem:active div {
  border-radius: 5px;
  background-color: #b9d4fc;
  border: 1px dotted black;
}

#taskbar .programItems .left {
  height: 300px;
  overflow-y: scroll;
}

#startMenu:first-child {
  display: contents;
}
