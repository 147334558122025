#clockWidget {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 98, 195, 1) 3%,
    rgba(9, 142, 237, 1) 80%,
    rgba(40, 178, 248, 1) 97%
  );
}

#clockWidget div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.menu-icon {
  cursor: pointer;
}
