.window {
  position: absolute;
  z-index: 9;
  text-align: center;
  display: block;

  min-width: 300px;
  min-height: 100px;
  resize: both;
  /* overflow: auto; */
  overflow: hidden;
}

.title-bar {
  cursor: move;
}

.title-bar-controls button {
  cursor: pointer;
}

.title-bar img {
  vertical-align: middle;
}

.title-bar-text {
  font-size: medium;
  vertical-align: text-top;
}
