.taskbaricon {
  display: flex;
  max-width: 150px;
  margin-right: 2px;
  background: linear-gradient(
    0deg,
    rgba(0, 98, 195, 1) 3%,
    rgba(9, 142, 237, 1) 80%,
    rgba(40, 178, 248, 1) 97%
  );
  cursor: pointer;
}

.taskbaricon:hover {
  background: linear-gradient(0deg, #0057ae 3%, #087fd3 80%, #15a7f0 97%);
}

.taskbaricon:focus {
  background: linear-gradient(0deg, red 3%, #036ebb 80%, #088cce 97%);
}

.taskbaricon span {
  padding: 10px;
}
